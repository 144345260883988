import { FindProducerResponse } from '../apis';
import EsmRepresentativeUtils from './rep-utils.basic';

const METERS_PER_MILE = 1609.344;

export const getRepresentativeTitle = (
  data: FindProducerResponse,
): string | undefined => {
  if (isRegionalDirector(data)) {
    return 'Regional Director';
  } else if (isRecruitingSalesManager(data)) {
    return 'Recruiting Sales Manager';
  } else if (isFinancialRepresentative(data)) {
    return 'Financial Representative';
  }
  return 'Sales Representative';
};

export const isRepresentative = (
  data: Pick<FindProducerResponse, 'orgType'>,
): boolean => {
  return (
    !!data.orgType &&
    ['ALPH', 'DELT', 'DELTR'].includes(data.orgType.toUpperCase())
  );
};

export const isRegionalDirector = (
  data: Pick<FindProducerResponse, 'orgType'>,
): boolean => {
  return EsmRepresentativeUtils.isRegionalDirector(data.orgType);
};

export const isFinancialRepresentative = (
  data: FindProducerResponse,
): boolean => {
  return isRepresentative(data) && !!data.effectiveDate;
};

export const isRecruitingSalesManager = (
  data: Pick<FindProducerResponse, 'orgType'>,
): boolean => {
  return EsmRepresentativeUtils.isRecruitingSalesManager(data.orgType);
};

export const isSpanishSpeaking = (data: FindProducerResponse): boolean => {
  return !!data.language && data.language.toUpperCase() === 'HSP';
};

const convertMetersToMiles = (
  meters: number | null | undefined,
): number | undefined => {
  if (meters === null || meters === undefined) {
    return undefined;
  }
  return Number(meters / METERS_PER_MILE);
};

export const getRepresentativeDistance = (
  data: FindProducerResponse,
): string | undefined => {
  const miles = convertMetersToMiles(data.distance);
  if (miles === undefined) {
    return undefined;
  }
  return `${miles.toFixed(1)} miles`;
};

/**
 * Sort producers by their distance in miles to one decimal precision, then secondarily sort any ties by name.
 * @param producers
 */
export const sortProducersByDistance = (producers: FindProducerResponse[]) => {
  return [...producers]
    .sort((a, b) =>
      getRepresentativeName(a).localeCompare(getRepresentativeName(b)),
    )
    .sort((a, b) => {
      const aMiles = convertMetersToMiles(a.distance);
      const bMiles = convertMetersToMiles(b.distance);
      if (typeof aMiles === 'number' && typeof bMiles === 'number') {
        return Number(aMiles.toFixed(1)) - Number(bMiles.toFixed(1));
      } else if (aMiles === undefined && typeof bMiles === 'number') {
        return 1;
      } else if (typeof aMiles === 'number' && bMiles === undefined) {
        return -1;
      } else {
        return 0;
      }
    });
};

export const getRepresentativeSite = (
  siteDirectory: string,
  isRsm: boolean,
  isRd: boolean,
) => {
  return EsmRepresentativeUtils.getRepresentativeSite(
    siteDirectory,
    isRsm,
    isRd,
  );
};

export const getRepresentativeName = (
  data: Pick<FindProducerResponse, 'firstName' | 'alias' | 'lastName'>,
): string => {
  return `${data.alias || data.firstName} ${data.lastName}`;
};

export const getRepresentativeLocation = (
  data: Pick<FindProducerResponse, 'city' | 'state' | 'zipCode'>,
): string => {
  return `${data.city}, ${data.state} ${data.zipCode}`;
};

export const getRepresentativePhone = (phone?: string): string => {
  const f_val = phone?.replace(/\D[^\.]/g, '');
  if (f_val?.length === 10)
    return (
      f_val?.slice(0, 3) + '-' + f_val?.slice(3, 6) + '-' + f_val?.slice(6)
    );

  return '';
};
