import { SimpleGrid, VStack } from '@chakra-ui/react';
import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { AllProducersQuery } from '../../../../graphql-types';
import Layout from '../../../components/Layout';
import { WLContainer, WLH1V2, WLLink } from '../../../design-library';
import {
  getRepresentativeName,
  getRepresentativeSite,
  isRecruitingSalesManager,
  isRegionalDirector,
} from '../../../utils/representative.utils';

type ProducerEntry = ArrayElement<AllProducersQuery['allProducer']['nodes']>;

interface ProducerListProps extends PageProps<AllProducersQuery> {}

const ProducerLink: React.FC<{
  data: ProducerEntry;
  rd: boolean;
  rsm: boolean;
}> = ({ data, rsm, rd }) => {
  if (!data.siteDirectory) {
    return null;
  }
  return (
    <WLLink to={getRepresentativeSite(data.siteDirectory, rsm, rd)}>
      {getRepresentativeName(data)}
    </WLLink>
  );
};

const ProducerListPage: React.FC<ProducerListProps> = ({ data, location }) => {
  const [rdList, rsmList, repList] = React.useMemo(() => {
    const rdList: ProducerEntry[] = [];
    const rsmList: ProducerEntry[] = [];
    const repList: ProducerEntry[] = [];
    data.allProducer.nodes.forEach((producer) => {
      if (isRegionalDirector(producer)) {
        rdList.push(producer);
      } else if (isRecruitingSalesManager(producer)) {
        rsmList.push(producer);
      } else {
        repList.push(producer);
      }
    });
    return [rdList, rsmList, repList];
  }, [data.allProducer.nodes]);

  return (
    <Layout
      seo={{
        title: 'Sales Representatives',
        description:
          'View the complete list of WoodmenLife Representatives and Recruiting Sales Managers.',
      }}
      currentPath={location.pathname}
    >
      <WLContainer width="NARROW">
        <VStack width="full" paddingY={4}>
          <WLH1V2>Regional Directors</WLH1V2>
          <SimpleGrid
            columns={[1, null, 2]}
            spacing={2}
            width="full"
            textAlign="center"
          >
            {rdList.map((rd, index) => (
              <ProducerLink key={index} data={rd} rsm={false} rd />
            ))}
          </SimpleGrid>
        </VStack>

        <VStack width="full" paddingY={4} sx={{ textAlign: 'center' }}>
          <WLH1V2>Recruiting Sales Managers</WLH1V2>
          <SimpleGrid
            columns={[1, null, 2]}
            spacing={2}
            width="full"
            textAlign="center"
          >
            {rsmList.map((rsm, index) => (
              <ProducerLink key={index} data={rsm} rsm rd={false} />
            ))}
          </SimpleGrid>
        </VStack>

        <VStack width="full" paddingY={4}>
          <WLH1V2>Our Representatives</WLH1V2>
          <SimpleGrid
            columns={[1, null, 2]}
            spacing={2}
            width="full"
            textAlign="center"
          >
            {repList.map((rep, index) => (
              <ProducerLink key={index} data={rep} rsm={false} rd={false} />
            ))}
          </SimpleGrid>
        </VStack>
      </WLContainer>
    </Layout>
  );
};

/*
Note: Legacy behavior sorts results by firstName(alias), just replicating that behavior here
 */
export const pageQuery = graphql`
  query AllProducers {
    allProducer(sort: { fields: [alias, lastName], order: [ASC, ASC] }) {
      nodes {
        siteDirectory
        firstName
        alias
        lastName
        orgType
      }
    }
  }
`;

export default ProducerListPage;
