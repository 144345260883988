/*
Have to structure this as an ESM-style module so it can be used by `gatsby-node` and the Search indexing process, both
of which do not support ES6-style imports.
 */

const isRegionalDirector = (orgType) => {
  return !!orgType && ['SM', 'SMSAL'].includes(orgType.toUpperCase());
};

const isRecruitingSalesManager = (orgType) => {
  return !!orgType && ['AM', 'AMSAL'].includes(orgType.toUpperCase());
};

const getRepresentativeSite = (siteDirectory, isRsm, isRd) => {
  let path;

  if (isRd) {
    path = `/rd/${siteDirectory}`;
  } else if (isRsm) {
    path = `/rsm/${siteDirectory}`;
  } else {
    path = `/reps/${siteDirectory}`;
  }

  if (!path.endsWith('/')) {
    path = `${path}/`;
  }
  return path;
};

module.exports = {
  isRecruitingSalesManager,
  isRegionalDirector,
  getRepresentativeSite,
};
